<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-3">
        Determine how the concentration of each species in the reaction shown below would change as
        a result of increasing the pressure of the system at constant temperature.
      </p>

      <p class="mb-5 pl-8">
        <chemical-latex content="N2(g) + 3 H2(g) <=> 2 NH3(g)" />
      </p>

      <p class="mb-n3">
        <chemical-latex content="a) Concentration of N2" />
      </p>

      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
          class="pl-6"
        />
      </v-radio-group>

      <p class="mb-n3">
        <chemical-latex content="b) Concentration of H2" />
      </p>
      <v-radio-group v-model="inputs.input2" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
          class="pl-6"
        />
      </v-radio-group>

      <p class="mb-n3">
        <chemical-latex content="c) Concentration of NH3" />
      </p>
      <v-radio-group v-model="inputs.input3" :disabled="!allowEditing">
        <v-radio
          v-for="option in options"
          :key="option.value"
          :value="option.value"
          :label="option.condition"
          class="pl-6"
        />
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question109',
  components: {ChemicalLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
      },
      options: [
        {condition: 'Increase', value: '1'},
        {condition: 'Decrease', value: '2'},
        {condition: 'Remain the same', value: '3'},
      ],
    };
  },
};
</script>
